











































import Vue from "vue";

import TextField from "@/components/formbuilder/TextField.vue";
import TextAreaField from "@/components/formbuilder/TextAreaField.vue";
import SelectField from "@/components/formbuilder/SelectField.vue";
import FormFieldList from "@/components/formbuilder/FormFieldList.vue";
import FormFieldSettings from "@/components/formbuilder/FormFieldSettings.vue";
import { Form, Role } from "@/types";
import FormBackgroundImage from "@/components/formbuilder/FormBackgroundImage.vue";
import formBuilderStoreModule from "@/store/modules/form";
import { createNamespacedHelpers } from "vuex";

const { mapActions: formActions } = createNamespacedHelpers("FORM");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: {
    TextField,
    TextAreaField,
    SelectField,
    FormFieldList,
    FormFieldSettings,
    FormBackgroundImage,
  },
  name: "ViewForm",
  props: {
    formId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    apiUrl: `${VUE_APP_API_URL}/v1/file`,
    valid: false,
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    form(): Form {
      return this.$store.getters["FORM/getForm"](this.formId);
    },
  },
  created() {
    if (this.formId) this.fetchForm(`?formId=${this.formId}`);
  },
  methods: {
    ...formActions(["fetchForm"]),
    validateForm() {
      const valid = (
        this.$refs.form as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      // logic to save data
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("FORM")) {
      this.$store.registerModule("FORM", formBuilderStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("FORM");
  },
});
